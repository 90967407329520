const CHANGE_SPEAKER_ERROR = new Error('Browser don\'t support change speaker');
CHANGE_SPEAKER_ERROR.name = 'CHANGE_SPEAKER_ERROR';


interface WCAudioBaseLevelOptions {
  /**
   * @description the frequent of analyze audio level(ms)
   * @default 20
   */
  analyserFrequent?: number;

  /**
   * @description callback when start analyser
   * @param volume: audio level
   */
  analyserCallback?: (volume: number) => any;
}

class WCAudioBaseLevel {
  options: WCAudioBaseLevelOptions;

  audioCtx: AudioContext;

  destinationNode: MediaStreamAudioDestinationNode;

  analyserNode: AnalyserNode;

  analyserNodeTimer: number | null;

  /**
   * @description Dataview for analyserNode buffer
   */
  analyserNodeBufferDataArray: Uint8Array | null;

  audioPlayer: HTMLAudioElement & {
    setSinkId: (deviceId: string) => Promise<void>;
  };

  /**
   * @description if analyze process is runing
   * @protected
   * @private
   */
  isAnalyzing: boolean;

  /**
   * @description if WCAudioOutputLevel instance has been destroyed already
   */
  isDestroyed: boolean;

  constructor(options?: WCAudioBaseLevelOptions) {
    this.options = {
      analyserFrequent: 20,
      ...(options || {}),
    };
    this.initAudioCtx();
    this.initAnalyserNode();
    this.initAudioPlayer();
    this.setAnalyzingStatus(false);
  }

  getAnalyzingStatus() {
    return !!this.isAnalyzing;
  }

  setAnalyzingStatus(flag: boolean) {
    this.isAnalyzing = !!flag;
  }

  getDestroyedStatus() {
    return !!this.isDestroyed;
  }

  setDestroyedStatus(destroyed: boolean) {
    this.isDestroyed = !!destroyed;
  }

  /**
   * @description init audioCtx
   * @protected
   * @private
   */
  initAudioCtx() {
    this.audioCtx = new AudioContext();
    this.destinationNode = new MediaStreamAudioDestinationNode(this.audioCtx);
  }

  /**
   * @description init analyserNode
   * @protected
   * @private
   */
  initAnalyserNode() {
    this.analyserNode = this.audioCtx.createAnalyser();
    this.analyserNode.connect(this.destinationNode);
    this.analyserNode.fftSize = 1024;
  }

  /**
   * @description interval callback for analyserNode
   * @protected
   * @private
   */
  analyserNodeIntervalCallback() {
    if (!this.analyserNodeBufferDataArray || !this.options.analyserCallback) return;
    this.analyserNode.getByteFrequencyData(this.analyserNodeBufferDataArray);
    let volumeSum = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const volume of this.analyserNodeBufferDataArray) volumeSum += volume;
    const averageVolume = volumeSum / this.analyserNodeBufferDataArray.length;
    this.options.analyserCallback(averageVolume);
  }

  /**
   * @description set analyserNode interval
   * @protected
   * @private
   */
  setAnalyzeInterval() {
    this.clearAnalyzeInterval();
    const bufferLength = this.analyserNode.frequencyBinCount;
    this.analyserNodeBufferDataArray = new Uint8Array(bufferLength);
    this.analyserNodeTimer = window.setInterval(
      this.analyserNodeIntervalCallback.bind(this),
      this.options.analyserFrequent,
    );
  }

  /**
   * @description clear analyserNode interval
   * @protected
   * @private
   */
  clearAnalyzeInterval() {
    if (this.analyserNodeTimer) {
      window.clearInterval(this.analyserNodeTimer);
      this.analyserNodeTimer = null;
      this.analyserNodeBufferDataArray = null;
    }
  }

  /**
   * @description stopAnalyze
   */
  stopAnalyze() {
    this.setAnalyzingStatus(false);
    this.clearAnalyzeInterval();
    this.stopAudioPlayer();
  }

  /**
   * @description startAnalyze
   * @public
   */
  startAnalyze(preHandle?: () => Promise<boolean>) {
    if (this.getAnalyzingStatus()) return Promise.reject(new Error('Analyze is runing already'));
    if (this.getDestroyedStatus()) return Promise.reject(new Error('WCAudioOutputLevel is destroyed already'));
    this.setAnalyzingStatus(true);
    return (preHandle ? preHandle() : Promise.resolve(true)).then(() => {
      this.setAnalyzeInterval();
      return this.startAudioPlayer();
    }).catch((e) => {
      this.stopAnalyze();
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    });
  }

  /**
   * @description init audioPlayer
   * @protected
   * @private
   */
  initAudioPlayer() {
    // @ts-ignore
    this.audioPlayer = new Audio();
    this.audioPlayer.loop = true;
    this.audioPlayer.srcObject = this.destinationNode.stream;
  }

  /**
   * @description stop audioPlayer
   * @protected
   * @private
   */
  stopAudioPlayer() {
    if (this.audioPlayer) {
      this.audioPlayer.currentTime = 0;
      this.audioPlayer.pause();
    }
  }

  /**
   * @description start audioPlayer
   * @protected
   * @private
   */
  startAudioPlayer() {
    this.resumeAudioCtx();
    this.audioPlayer.currentTime = 0;
    return this.audioPlayer.play();
  }

  /**
   * @description resume audio ctx state
   */
  resumeAudioCtx() {
    if (this.audioCtx.state !== 'running') {
      this.audioCtx.resume();
    }
  }
}

interface AudioRecorderOptions {
  /**
   * @description the max duration of recording an stream
   * @default 10000 ms
   */
  maxRecordDuration?: number;
}

class AudioRecorder {
  options: AudioRecorderOptions;

  recorder: MediaRecorder | null;

  isRecording: boolean;

  chunks: Blob[];

  audioPlayer: HTMLAudioElement & {
    setSinkId: (deviceId: string) => Promise<void>;
  };

  timer: number | null;

  shouldPlayAfterStop: boolean;

  constructor(options?: AudioRecorderOptions) {
    this.options = {
      maxRecordDuration: 10000,
      ...(options || {}),
    };
    this.isRecording = false;
    this.chunks = [];
    this.initAudioPlayer();
  }

  isSupportReording() {
    return typeof MediaRecorder === 'function';
  }

  /**
   * @description start a record process
   * @param stream record audio stream
   * @public
   */
  start(stream: MediaStream) {
    if (!this.isSupportReording()) {
      return Promise.reject(new Error('Broswer doesn\'t support MeidaRecorder'));
    }
    if (this.isRecording) {
      return Promise.reject(new Error('Recording is running already'));
    }
    const recordPromise = new Promise<string>((resolve, reject) => {
      this.isRecording = true;
      this.setRecordTimer();
      this.recorder = new MediaRecorder(stream);
      this.recorder.ondataavailable = this.handleDataAvailable.bind(this);
      this.recorder.onstop = () => {
        resolve(this.handleRecordStopped());
      };
      this.recorder.onerror = reject;
      this.recorder.start();
    }).finally(this.clearRecordStatus.bind(this));
    const playRecordPromise = recordPromise.then((blobUrl) => {
      if (blobUrl) {
        return this.startAudioPlayer(blobUrl);
      }
      return false;
    });
    return Promise.resolve({
      recordPromise,
      playRecordPromise,
    });
  }

  /**
   * @description set a timer for recording
   * @protected
   * @private
   */
  setRecordTimer() {
    this.clearRecordTimer();
    this.timer = window.setTimeout(this.stop.bind(this), this.options.maxRecordDuration);
  }

  /**
   * @description clear a recording timer
   * @protected
   * @private
   */
  clearRecordTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  handleDataAvailable(event: { data: Blob }) {
    if (event.data) {
      this.chunks.push(event.data);
    }
  }

  /**
   * @description trigger when record stopped
   */
  handleRecordStopped() {
    if (this.chunks.length > 0 && this.shouldPlayAfterStop) {
      const blob = new Blob(this.chunks, {
        type: this.chunks[0]?.type || 'audio/ogg; codecs=opus',
      });
      return window.URL.createObjectURL(blob);
    }
    return '';
  }

  /**
   * @description clear all record status
   * @protected
   * @private
   */
  clearRecordStatus() {
    this.clearRecordTimer();
    if (this.recorder) {
      this.recorder.ondataavailable = null;
      this.recorder.onstop = null;
    }
    this.recorder = null;
    this.isRecording = false;
    this.chunks = [];
    this.shouldPlayAfterStop = false;
  }

  /**
   * @description stop a record process
   * @public
   */
  stop(shouldPlayAfterStop: boolean = true) {
    if (!this.recorder || !this.isRecording) return;
    this.shouldPlayAfterStop = shouldPlayAfterStop;
    this.recorder.stop();
  }

  /**
   * @description init audioPlayer
   * @protected
   * @private
   */
  initAudioPlayer() {
    // @ts-ignore
    this.audioPlayer = new Audio();
  }

  /**
   * @description stop audioPlayer
   * @protected
   * @private
   */
  stopAudioPlayer() {
    if (this.audioPlayer) {
      this.audioPlayer.pause();
    }
  }

  /**
   * @description clear audioPlayer status
   */
  clearAudioPlayerStatus() {
    this.audioPlayer.currentTime = 0;
    this.audioPlayer.src = '';
    this.audioPlayer.onended = null;
    this.audioPlayer.onpause = null;
    this.audioPlayer.onerror = null;
  }

  /**
   * @description start audioPlayer
   * @protected
   * @private
   */
  startAudioPlayer(blobUrl: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.audioPlayer.src = blobUrl;
      this.audioPlayer.currentTime = 0;
      this.audioPlayer.play().catch(reject);
      this.audioPlayer.onended = () => {
        window.URL.revokeObjectURL(blobUrl);
        resolve(true);
      };
      this.audioPlayer.onpause = () => {
        window.URL.revokeObjectURL(blobUrl);
        resolve(true);
      };
      this.audioPlayer.onerror = reject;
    }).finally(this.clearAudioPlayerStatus.bind(this));
  }

  /**
   * @description change current speaker
   * @param deviceId current speaker deviceId
   */
  changeSpeaker(deviceId: string) {
    if (!this.audioPlayer.setSinkId) return Promise.reject(CHANGE_SPEAKER_ERROR);
    return this.audioPlayer.setSinkId(deviceId);
  }
}

/**
 * @description class used to hanlde audio output level
 * @export
 * @class WCAudioOutputLevel
 */
export class WCAudioOutputLevel extends WCAudioBaseLevel {
  sourceNode: AudioBufferSourceNode | null;

  sourceNodeBuffer: AudioBuffer | null;

  static IsBrowserSupport() {
    return typeof AudioContext === 'function'
      && typeof MediaStreamAudioDestinationNode === 'function'
      && Boolean(AudioContext.prototype.createAnalyser)
      && Boolean(AudioContext.prototype.createBufferSource)
      && AudioContext.prototype.decodeAudioData;
  }

  static IsBrowserSupportChangeSpeaker() {
    return typeof Audio.prototype.setSinkId === 'function';
  }

  /**
   * @description start analyze
   * @public
   * @param audioData
   * @param [deviceId] set current speaker deviceId
   */
  start(audioData: ArrayBuffer, deviceId?: string) {
    if (!audioData) return Promise.reject(new Error('No audio data to test speaker'));
    return this.startAnalyze(() => this.initSourceNode(audioData)).then(() => {
      if (deviceId) return this.changeSpeaker(deviceId, false);
      return undefined;
    });
  }

  /**
   * @description stop analyze
   */
  stop() {
    this.stopAnalyze();
    this.clearSourceNode();
  }


  /**
   * @description init the source node need to analyze
   * @param audioData ArrayBuffer audio data
   * @protected
   * @private
   */
  initSourceNode(audioData: ArrayBuffer) {
    this.clearSourceNode();
    return (this.sourceNodeBuffer
      ? Promise.resolve(this.sourceNodeBuffer)
      : this.audioCtx.decodeAudioData(audioData)
    ).then((audioBuffer) => {
      this.sourceNode = this.audioCtx.createBufferSource();
      this.sourceNodeBuffer = audioBuffer;
      this.sourceNode.buffer = audioBuffer;
      this.sourceNode.loop = true;
      this.sourceNode.connect(this.analyserNode);
      this.sourceNode.start(0);
      this.resumeAudioCtx();
      return true;
    }).catch((e) => {
      this.clearSourceNode();
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    });
  }

  /**
   * @description clear the source node need to analyze
   */
  clearSourceNode() {
    if (this.sourceNode) {
      this.sourceNode.stop(0);
      this.sourceNode.disconnect(this.analyserNode);
      this.sourceNode.buffer = null;
      this.sourceNode = null;
    }
  }

  /**
   * @description destroy current instance
   */
  destroy() {
    this.stop();
    this.analyserNode.disconnect(this.destinationNode);
    this.setDestroyedStatus(true);
    this.audioCtx.close();
    this.sourceNodeBuffer = null;
  }

  /**
   * @description change current speaker
   * @param deviceId current speaker deviceId
   * @param [shouldStop=true] if should stop analyze when change current speaker
   */
  changeSpeaker(deviceId: string, shouldStop: boolean = true) {
    if (shouldStop) this.stop();
    if (!this.audioPlayer.setSinkId) return Promise.reject(CHANGE_SPEAKER_ERROR);
    return this.audioPlayer.setSinkId(deviceId);
  }
}

interface WCAudioInputLevelOptions extends WCAudioBaseLevelOptions, AudioRecorderOptions {}

/**
 * @description class used to hanlde audio input level
 * @export
 * @class WCAudioInputLevel
 */
export class WCAudioInputLevel extends WCAudioBaseLevel {
  audioStream: MediaStream | null;

  sourceNode: MediaStreamAudioSourceNode | null;

  audioRecorder: AudioRecorder | null;

  currentMicrophoneDeviceId: string | null;

  constructor(options?: WCAudioInputLevelOptions) {
    const {
      maxRecordDuration,
      ...restOptions
    } = options || {};
    super(restOptions);
    this.audioRecorder = new AudioRecorder(maxRecordDuration
      ? { maxRecordDuration }
      : undefined);
  }

  static IsBrowserSupport() {
    return typeof AudioContext === 'function'
      && typeof MediaStreamAudioDestinationNode === 'function'
      && Boolean(AudioContext.prototype.createAnalyser)
      && Boolean(AudioContext.prototype.createMediaStreamSource)
      && window.navigator.mediaDevices?.getUserMedia;
  }

  static IsBrowserSupportChangeSpeaker() {
    return typeof Audio.prototype.setSinkId === 'function';
  }

  /**
   * @description init the source node need to analyze
   * @param deviceId set current microphone deviceId
   * @protected
   * @private
   */
  initSourceNode(deviceId?: string) {
    this.clearSourceNode();
    const currentDeviceId = deviceId || this.currentMicrophoneDeviceId;
    return window.navigator.mediaDevices
      .getUserMedia({ audio: currentDeviceId ? { deviceId: currentDeviceId } : true })
      .then((stream) => {
        this.audioStream = stream;
        this.sourceNode = this.audioCtx.createMediaStreamSource(stream);
        this.sourceNode.connect(this.analyserNode);
        this.resumeAudioCtx();
        return true;
      }).catch((e) => {
        this.clearSourceNode();
        // eslint-disable-next-line no-console
        console.error(e);
        throw e;
      });
  }

  /**
   * @description clear the source node need to analyze
   */
  clearSourceNode() {
    if (this.sourceNode) {
      this.sourceNode.disconnect(this.analyserNode);
      this.sourceNode = null;
    }
    if (this.audioStream) {
      this.audioStream.getAudioTracks().forEach((track) => {
        track.stop();
      });
      this.audioStream = null;
    }
    if (this.audioRecorder) {
      this.stopRecord(false);
      this.audioRecorder.stopAudioPlayer();
    }
  }

  /**
   * @description start analyze
   * @public
   * @param [deviceId] set current microphone deviceId
   */
  start(deviceId?: string) {
    return this.startAnalyze(() => {
      this.audioPlayer.muted = true;
      return this.initSourceNode(deviceId);
    });
  }

  /**
   * @description stop analyze
   */
  stop() {
    this.stopAnalyze();
    this.clearSourceNode();
  }

  /**
   * @description change current microphone
   * @param deviceId current microphone deviceId
   */
  changeMicrophone(deviceId: string) {
    this.currentMicrophoneDeviceId = deviceId;
    if (this.audioStream && this.getAnalyzingStatus()) {
      return this.initSourceNode(deviceId);
    }
    return Promise.resolve(true);
  }

  /**
   * @description destroy current instance
   */
  destroy() {
    this.stop();
    this.analyserNode.disconnect(this.destinationNode);
    this.setDestroyedStatus(true);
    this.audioCtx.close();
    this.currentMicrophoneDeviceId = null;
  }

  /**
   * @description start to record a duration of stream
   * @public
   */
  startRecord() {
    if (!this.getAnalyzingStatus()) return Promise.reject(new Error('Analyze is not runing'));
    if (!this.audioStream) return Promise.reject(new Error('Audio input stream is not capturing'));
    if (!this.audioRecorder) return Promise.reject(new Error('Recorder has been destroyed'));
    return this.audioRecorder.start(this.audioStream);
  }

  /**
   * @description stop to record a duration of stream
   * @public
   * @param [shouldPlayAfterStop] if need to play auto after record stopped
   */
  stopRecord(shouldPlayAfterStop: boolean = true) {
    if (!this.getAnalyzingStatus() || !this.audioStream || !this.audioRecorder?.isRecording) return;
    this.audioRecorder.stop(shouldPlayAfterStop);
  }

  /**
   * @description change current speaker
   * @param deviceId current speaker deviceId
   */
  changeSpeaker(deviceId: string) {
    if (this.audioRecorder) {
      this.audioRecorder.changeSpeaker(deviceId);
    }
    if (!this.audioPlayer.setSinkId) return Promise.reject(CHANGE_SPEAKER_ERROR);
    return this.audioPlayer.setSinkId(deviceId);
  }
}

export default {
  WCAudioInputLevel,
  WCAudioOutputLevel,
};
