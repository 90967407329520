import {
  isMicDeviceValid,
  isSpeakerDeviceValid,
  isChrome,
  isMobileDevice,
} from '../utils';

import i18n from '../constants/i18n';

const getEmptyLabel = (idx) => {
  return `Unrecognized microphone${idx}`;
};

const defaultDevice = { deviceId: 'default', label: i18n.DefaultDeviceLabel };

export default class {
  constructor(onInit) {
    this._init(onInit);
  }

  _init(onInit) {
    this._enumerateDevices().then(({ microphones, speakers, cameras }) => {
      this._microphones = microphones;
      this._speakers = speakers;
      this._cameras = cameras;
      if (onInit) {
        onInit({ microphones, speakers, cameras });
      }
    });
  }

  _enumerateDevices() {
    const microphones = [];
    const speakers = [];
    const cameras = [];
    return new Promise((resolve, reject) => {
      if (typeof navigator.mediaDevices !== 'object') {
        reject('mediaDevices not supported');
        return;
      }
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        if (!Array.isArray(devices)) {
          resolve({ microphones, speakers, cameras });
          return;
        }
        devices.forEach((device) => {
          const { kind, deviceId, label } = device;
          switch (kind) {
            case 'audioinput': {
              if (deviceId === 'default') {
                microphones.push({ deviceId, label: i18n.DefaultDeviceLabel });
              } else if (isMicDeviceValid(deviceId, label)) {
                microphones.push({
                  deviceId,
                  label: label || getEmptyLabel(microphones.length),
                });
              }
              break;
            }
            case 'audiooutput': {
              if (deviceId === 'default') {
                speakers.push({ deviceId, label: i18n.DefaultDeviceLabel });
              } else if (isSpeakerDeviceValid(deviceId, label)) {
                // Android Chrome dose not support speaker change
                const isAndroidChrome = isMobileDevice() && isChrome();
                if (!isAndroidChrome) {
                  speakers.push({
                    deviceId,
                    label: label || getEmptyLabel(speakers.length),
                  });
                }
              }
              break;
            }
            case 'videoinput': {
              cameras.push({
                deviceId,
                label: label || getEmptyLabel(cameras.length),
              });
              break;
            }
            default:
              break;
          }
        });
        [microphones, speakers, cameras].forEach((devices) => {
          if (devices.findIndex((e) => e.deviceId === 'default') === -1) {
            devices.push(defaultDevice);
          }
        });
        resolve({ microphones, speakers, cameras });
      });
    });
  }

  findDevice(deviceId, deviceLabel) {
    const devices = [].concat(this._microphones, this._speakers, this._cameras);
    return devices.find((device) => {
      return device.label === deviceLabel || device.deviceId === deviceId;
    });
  }
}
