import i18n from './i18n';

export const VIDEO_OPTIONS_FROM_PO = {
  TURN_OFF_VIDEO_WHEN_JOIN: 'turn-off-video-when-join',
};
export const VIDEO_OPTIONS_FROM_LOCAL = {
  HIDE_NON_VIDEO: 'hide-non-video',
  HIDE_SELF: 'hide-self',
  SEE_SELF_AS_ACTIVE_SPEAKER: 'see-self-as-active-speaker',
  HWAC_FOR_RECEIVE: 'hardware-acceleration-for-receive',
  HWAC_FOR_SEND: 'hardware-acceleration-for-send',
};
export const VIDEO_OPTIONS = {
  ...VIDEO_OPTIONS_FROM_PO,
  ...VIDEO_OPTIONS_FROM_LOCAL,
};

export const videoOptionsConfig = [
  {
    key: VIDEO_OPTIONS.TURN_OFF_VIDEO_WHEN_JOIN,
    desc: i18n.TurnOffVideoWhenJoin,
  },
  {
    key: VIDEO_OPTIONS.HIDE_NON_VIDEO,
    desc: i18n.HideNonVideo,
  },
  {
    key: VIDEO_OPTIONS.HIDE_SELF,
    desc: i18n.HideSelfView,
  },
  {
    key: VIDEO_OPTIONS.SEE_SELF_AS_ACTIVE_SPEAKER,
    desc: i18n.SeeSelfAsActiveWhileSpeaking,
  },
  {
    title: i18n.UserHardwareAcceleration,
    optionsConfig: [
      {
        key: VIDEO_OPTIONS.HWAC_FOR_RECEIVE,
        desc: i18n.ReceivingVideo,
      },
      {
        key: VIDEO_OPTIONS.HWAC_FOR_SEND,
        desc: i18n.SendingVideo,
      },
    ],
  },
];
