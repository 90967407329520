import { AUDIO_OPTIONS } from './audioOptions';
import { VIDEO_OPTIONS } from './videoOptions';

export const LOCAL_STORAGE_KEYS = {
  [AUDIO_OPTIONS.HOLD_SPACE_TO_UNMUTE]:
    'WEB-CLIENT-ENABLE-HOLD-SPACE-KEY-TO-UNMUTE',
  [AUDIO_OPTIONS.SYNC_BUTTONS_ON_HEADSET]:
    'WEB-CLIENT-ENABLE-SYNC-BUTTONS-ON-HEADSET',
  [VIDEO_OPTIONS.HWAC_FOR_RECEIVE]:
    'WEB-CLIENT-ENABLE-HARDWARE-ACCELERATION-FOR-RECEIVING-VIDEO-NEW',
  [VIDEO_OPTIONS.HWAC_FOR_SEND]:
    'WEB-CLIENT-ENABLE-HARDWARE-ACCELERATION-FOR-SENDING-VIDEO',
  [VIDEO_OPTIONS.HIDE_NON_VIDEO]: 'WEB-CLIENT-ENABLE-HIDE-NON-VIDEO',
  [VIDEO_OPTIONS.HIDE_SELF]: 'WEB-CLIENT-ENABLE-HIDE-SELF-VIEW',
  [VIDEO_OPTIONS.SEE_SELF_AS_ACTIVE_SPEAKER]:
    'WEB-CLIENT-ENABLE-SEE-SELF-ACTIVE-WHEN-SPEAKING',
  ACTIVE_SPEAKER: 'activeSpeakerDeviceId',
  ACTIVE_MIC: 'activeMicrophoneDeviceId',
};
