/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import SettingsContext from '../context/SettingsContext';

const useVideo = () => {
  const ctx = useContext(SettingsContext);
  const { transaction } = ctx;
  useEffect(() => {
    transaction.startVideoWithoutSDK();
    return () => {
      console.log('unmount video pane');
      transaction.stopVideoWithoutSDK();
    };
  }, []);
};

export default useVideo;
