export const DATA_KEY = 'easy-local-storage';

export const PREVIEW_OPTIONS = {
  AUTO_JOIN_AUDIO: 1,
  AUDIO_ON: 1 << 1,
  VIDEO_ON: 1 << 2,
};

export const CANVAS_ID = 'settings-canvas';

export const CONTROL_MODE_CONNECTORS_ENUM = Object.freeze({
  MSFT: 'MSFT',
  GOOGLE_NEST: 'GOOGLE_NEST',
  GOOGLE_MEET: 'GOOGLE_MEET',
  TESLA: 'TESLA',
  NORMAL: 'NORMAL',
});
