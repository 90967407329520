import DataManager from '../data-manager';

let instance = null;

export default () => {
  if (instance === null) {
    instance = new DataManager();
  }
  return instance;
};
