import { iText } from '../utils/index';

export default {
  InputLevel: iText('Input level', 'apac.settings.input_level'),
  Microphone: iText('Microphone', 'apac.settings.microphone'),
  Speaker: iText('Speaker', 'apac.settings.speaker'),
  OutputLevel: iText('Output level', 'apac.settings.output_level'),
  Stop: iText('Stop', 'apac.settings.stop'),
  TestSpeaker: iText('Test Speaker', 'apac.settings.test_speaker'),
  TestMic: iText('Test Mic', 'apac.settings.test_mic'),
  Recording: iText('Recording', 'apac.settings.recording'),
  Playing: iText('Playing', 'apac.settings.playing'),
  TurnOffVideoWhenJoin: iText(
    'Turn off my video when join a meeting',
    'apac.settings.turnoff_video_when_join',
  ),
  HideNonVideo: iText(
    'Hide Non-video Participants',
    'apac.meeting_hide_non_video',
  ),
  HideSelfView: iText('Hide Self View', 'apac.meeting_hide_self_view'),
  SeeSelfAsActiveWhileSpeaking: iText(
    'See myself as the active speaker while speaking',
    'apac.settings.see_self_as_active_when_speaking',
  ),
  SpeakerTip: iText(
    'Click Test Speaker to make sure you can hear others',
    'apac.settigns.speaker_tip',
  ),
  MicTip: iText(
    'Click Test Mic to make sure others can hear you',
    'apac.settings.mic_tip',
  ),
  MicSelectTip: iText(
    'If you cannot hear your voice, select a different microphone',
    'apac.settings.mic_select_tip',
  ),
  SpeakerSelectTip: iText(
    'If you cannot hear the test sound, select a different speaker',
    'apac.settings.speaker_select_tip',
  ),
  MuteMicWhenJoin: iText(
    'Mute my microphone when join a meeting',
    'apac.settings.mute_when_hoin',
  ),
  AutoJoinAudio: iText(
    'Automatically join audio by computer when joining a meeting',
    'apac.settings.auto_join_audio',
  ),
  HoldSpaceToUnmute: iText(
    'Press and hold SPACE key to temporarily unmute yourself',
    'apac.settings.hold_space',
  ),
  SyncButtonsOnHeadSet: iText(
    'Sync buttons on headset',
    'apac.settings.sync_headset',
  ),
  DefaultDeviceLabel: iText('Same as System', 'apac.same_as_system'),
  UserHardwareAcceleration: iText(
    'Use hardware acceleration for:',
    'apac.settings.use_hardware_acceleration',
  ),
  ReceivingVideo: iText('Receiving video', 'apac.settings.receiving_video'),
  SendingVideo: iText('Sending video', 'apac.settings.sending_video'),
  Volume: iText('Volume', 'pwa.volume'),
};
