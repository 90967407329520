/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { videoOptionsConfig } from '../../constants/videoOptions';
import { has } from 'lodash-es';
import Checkbox from '../checkbox';
import SettingsContext from '../../context/SettingsContext';
import { CANVAS_ID } from '../../constants/index';
import useVideo from '../../hooks/useVideo';
import './VideoPane.scss';

const HardwareAccelerationOption = (props) => {
  const { title, optionsConfig } = props;
  const ctx = useContext(SettingsContext);
  const { transaction } = ctx;

  //todo tips
  return (
    <>
      <div className="settings-sub-label" id="hardware-title">
        {title}
      </div>
      <div
        className="settings-sub-options"
        role="group"
        aria-labelledby="hardware-title"
      >
        {optionsConfig.map((option) => (
          <div className="option-wrapper-inline" key={option.key}>
            <Checkbox
              key={option.key}
              className="settings-config-checkbox inline"
              checked={transaction.getOptionValue(option.key)}
              onChange={(checked) => {
                transaction.setOptionValue(option.key, checked);
              }}
            >
              {option.desc}
            </Checkbox>
          </div>
        ))}
      </div>
    </>
  );
};

HardwareAccelerationOption.propTypes = {
  title: PropTypes.string,
  optionsConfig: PropTypes.array,
};
const VideoPane = (props) => {
  const { className } = props;
  const ctx = useContext(SettingsContext);
  const { transaction } = ctx;
  useVideo();
  const optionsContent = videoOptionsConfig.map((option) => {
    if (has(option, 'optionsConfig')) {
      return (
        <HardwareAccelerationOption {...option} key="hardware-acceleration" />
      );
    }
    return (
      <div className="option-wrapper" key={option.key}>
        <Checkbox
          className="settings-config-checkbox"
          checked={transaction.getOptionValue(option.key)}
          onChange={(checked) => {
            transaction.setOptionValue(option.key, checked);
          }}
        >
          {option.desc}
        </Checkbox>
      </div>
    );
  });
  return (
    <div className={clsx('zm-video-pane', className)}>
      <div className="video-canvas-wrapper">
        {/* <canvas id={CANVAS_ID} width="480" height="270" /> */}
        <video
          id={CANVAS_ID}
          style={{ width: 480, height: 270, objectFit: 'cover' }}
          autoPlay
          playsInline
        />
      </div>
      {optionsContent}
    </div>
  );
};

export default VideoPane;

VideoPane.propTypes = {
  className: PropTypes.string,
};
