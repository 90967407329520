import getDataManager from './getDataManager';
import { LOCAL_STORAGE_KEYS } from '../constants/localstorage-keys';

export default (optionKey) => {
  const dataManager = getDataManager();
  const localKey = LOCAL_STORAGE_KEYS[optionKey];
  if (!localKey) {
    return null;
  }
  return dataManager.getValue(localKey);
};
