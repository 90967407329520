import { DATA_KEY } from '../constants';
import { decodeBase64, encodeBase64 } from '../utils';

class DataManager {
  static readLocalData() {
    const localData = localStorage.getItem(encodeBase64(DATA_KEY));
    if (!localData) {
      return {};
    }
    return JSON.parse(decodeBase64(localData));
  }

  constructor() {
    this._data = DataManager.readLocalData();
  }

  getValue(key) {
    return this._data[key];
  }

  setValue(key, value) {
    this._data[key] = value;
    localStorage.setItem(
      encodeBase64(DATA_KEY),
      encodeBase64(JSON.stringify(this._data)),
    );
  }
}

export default DataManager;
