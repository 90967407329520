import React from 'react';
import PropTypes from 'prop-types';
import './AudioProgress.scss';

const AudioProgress = (props) => {
  const { level, ...rest } = props;

  return (
    <div
      tabIndex={0}
      className="zm-settings-audio-progress"
      aria-valuemax="100"
      aria-valuemin="0"
      role="progressbar"
      aria-valuenow={level}
      {...rest}
    >
      <div className="audio-progress-inner" style={{ width: `${level}%` }} />
    </div>
  );
};

AudioProgress.propTypes = {
  level: PropTypes.number,
};

export default AudioProgress;
