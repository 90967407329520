/**
 * @description feature flag type
 */
export const FEATUTRE_FLAG = {
  FEATUTRE_FLAG_UNDEFINE: undefined,
  FEATUTRE_FLAG_ENABLE: true,
  FEATUTRE_FLAG_DISABLE: false,
};

type ValueOf<T> = T[keyof T];

/**
 * @description feature flag typescript type
 */
export type FeatureFlagType = ValueOf<typeof FEATUTRE_FLAG>;

/**
 * @description feature flag rule type
 */
export type RuleFunc = (flagBit: number, item?: MapRuleItem) => FeatureFlagType;

/**
 * @description feature flag map rule item type
 */
export interface MapRuleItem {
  checked: boolean | ((flagBit: number, item: MapRuleItem) => boolean);
  rule?: RuleFunc;
}

/**
 * @description an utility for handle op feature options in webclient or websdk
 */
export class OpFeatureUtility {
  featureOptions: string

  constructor(featureOptions: string) {
    this.featureOptions = featureOptions || '';
  }

  /**
   * @description get target index op feature flag
   * @export
   * @param littleEndianBegin little endian order index, begin with 1
   * @param [flagLength=1] bit length of flag
   * @returns hexadecimal bit number | undefined
   */
  getOpFeatureFlagBit(littleEndianBegin: number, flagLength = 1) {
    const len = this.featureOptions.length - littleEndianBegin - flagLength + 1;
    if (len < 0) {
      return undefined;
    }
    const featureFlag = this.featureOptions.slice(
      len,
      this.featureOptions.length - littleEndianBegin + 1,
    );
    if (!featureFlag) return undefined;
    return parseInt(featureFlag, 16);
  }

  /**
   * @description get FEATUTRE_FLAG status
   * @param littleEndianBegin littleEndianBegin little endian order index, begin with 1
   * @param [flagLength=1] bit length of flag
   * @param [rule] rule function
   * @return FeatureFlagType
   */
  getOpFeatureFlag(littleEndianBegin: number, flagLength = 1, rule?: RuleFunc): FeatureFlagType {
    const flagBit = this.getOpFeatureFlagBit(littleEndianBegin, flagLength);
    if (flagBit === undefined) return FEATUTRE_FLAG.FEATUTRE_FLAG_UNDEFINE;
    if (rule) {
      const result = rule(flagBit);
      if (result !== undefined && typeof result !== 'boolean') {
        throw new Error('Rule must return FEATUTRE_FLAG type value');
      }
      return result;
    }
    if (flagBit !== 0) return FEATUTRE_FLAG.FEATUTRE_FLAG_ENABLE;
    return FEATUTRE_FLAG.FEATUTRE_FLAG_DISABLE;
  }

  /**
   * @description get FEATUTRE_FLAG status from rule map
   * @param littleEndianBegin littleEndianBegin little endian order index, begin with 1
   * @param [flagLength=1] bit length of flag
   * @param [rule=() => {}]
   * @param [map=[]] rule map list
   * @return FeatureFlagType
   */
  getOpFeatureFlagFromRuleMap(
    littleEndianBegin: number,
    flagLength = 1,
    rule: RuleFunc | null,
    map: MapRuleItem[] = [],
  ): FeatureFlagType {
    const flagBit = this.getOpFeatureFlagBit(littleEndianBegin, flagLength);
    if (flagBit === undefined) return FEATUTRE_FLAG.FEATUTRE_FLAG_UNDEFINE;
    const targetItem = map.find((item) => {
      if (typeof item.checked === 'function') {
        return item.checked(flagBit, item);
      }
      return !!item.checked;
    });
    if (!targetItem) return FEATUTRE_FLAG.FEATUTRE_FLAG_DISABLE;
    if (targetItem.rule) return targetItem.rule(flagBit, targetItem);
    if (!rule) return FEATUTRE_FLAG.FEATUTRE_FLAG_DISABLE;
    return rule(flagBit, targetItem);
  }
}
