import { extend, noop, uniqueId } from 'lodash';

const defaultOptions = {
  timeout: 0,
  deps: [],
};

export const JobCancelError = Error('Job was cancelled.');

interface JobObj {
  resolve: (payload?: any) => void;
  reject: (payload?: any) => void;
  result: any;
  job: Promise<any>;
  deps: string[];
  results?: object[];
  timer: number;
}

interface JobOptions {
  timeout?: number,
  deps?: string[]
}

function assertIsJobObj(obj: JobObj | undefined): asserts obj is JobObj {
  if (obj == null) throw new Error('job not exist');
}

export class Job {
  private static jobs: Map<string, JobObj> = new Map();

  private static deps: Map<string, string[]> = new Map();

  private static getJob(id: string): JobObj {
    const obj = Job.jobs.get(id);
    assertIsJobObj(obj);
    return obj;
  }

  static start(id: string, startFn = noop, options?: JobOptions): Promise<any> {
    if (!id) {
      throw Error('id required!');
    }
    if (Job.check(id)) {
      Job.fail(id, JobCancelError);
    }
    const finalOptions = extend({}, defaultOptions, options);
    const { timeout, deps } = finalOptions;
    const jobObj: JobObj = {
      deps, result: [], resolve: noop, reject: noop, job: Promise.resolve(), timer: 0
    };
    const job = new Promise((resolve, reject) => {
      jobObj.resolve = resolve;
      jobObj.reject = reject;
      Job.jobs.set(id, jobObj);
      startFn();
    });
    const taskArr = [job];
    if (timeout) {
      const timeoutPromise = new Promise((_, reject) => {
      jobObj.timer =
        window.setTimeout(() => {
          reject(Error(`${id} job timeout after ${timeout} ms`));
        }, timeout);
      });
      taskArr.push(timeoutPromise)
    }
    jobObj.job = Promise.race(taskArr);
    deps.forEach((dep) => {
      const relyOnJobs = Job.deps.get(dep);
      if (relyOnJobs == null) {
        Job.deps.set(dep, [id]);
      } else {
        relyOnJobs.push(id);
      }
    });
    return jobObj.job;
  }

  static watch(id: string) {
    if (Job.jobs.has(id)) {
      return Job.getJob(id).job;
    }
    const uid = uniqueId();
    return Job.start(uid, noop, { deps: [id] });
  }

  static check(id: string) {
    return Job.jobs.has(id);
  }

  // for sync job
  static completeSync(id: string, payload?: any) {
    Job.start(id);
    Job.complete(id, payload);
  }

  static complete(id: string, payload?: any) {
    const jobObj = Job.getJob(id);
    const incompleteDeps = jobObj.deps;
    if (incompleteDeps.length > 0) {
      throw Error(`dependent step ${incompleteDeps} not complete yet!`);
    }
    jobObj.result = payload;
    jobObj.resolve(payload);
    if (jobObj.timer) {
      clearTimeout(jobObj.timer)
    }
    // all jobs that rely on this job should reduce a dependency.
    const relyOnJobs = Job.deps.get(id);
    if (Array.isArray(relyOnJobs)) {
      relyOnJobs.forEach((job) => {
        const jobObj = Job.getJob(job);
        const { deps, result } = jobObj;
        const idx = deps.indexOf(id);
        if (idx > -1) {
          deps.splice(idx, 1);
          result.push(payload);
        }
        // if all dependencies complete, job should complete.
        if (deps.length === 0) {
          Job.complete(job, result);
        }
      });
    }
  }

  static fail(id: string, reason: string | Error) {
    if (!Job.jobs.has(id)) {
      return
    }
    const job = Job.getJob(id);
    job.reject(reason);
    if (job.timer) {
      clearTimeout(job.timer)
    }
    // all jobs that rely on this job should fail too.
    const relyOnJobs = Job.deps.get(id);
    if (Array.isArray(relyOnJobs)) {
      relyOnJobs.forEach((job) => {
        Job.fail(job, `dependent job ${job} failed`);
      });
    }
  }
}

export default Job;
