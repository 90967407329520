import { CONTROL_MODE_CONNECTORS_ENUM } from '../constants';
import { I18nLoader } from './load-apac-resource';
import { Base64 } from 'js-base64';

export const queryBit = (value, bit) => {
  return Boolean(value & bit);
};

export const toggleBit = (value, bit) => {
  return value ^ bit;
};

const i18n = new I18nLoader(window.langResource);

export function iText(defaultText, resourceKey, resourceParameter) {
  if (typeof i18n === 'undefined') {
    return defaultText || resourceKey;
  }
  const i18nText =
    typeof resourceParameter !== 'undefined'
      ? i18n.get(resourceKey, resourceParameter)
      : i18n.get(resourceKey);
  if (!!i18nText && i18nText !== resourceKey) {
    return i18nText;
  }
  return defaultText || resourceKey;
}

export function decodeBase64(value) {
  return Base64.decode(value, true);
}

export function encodeBase64(value) {
  return Base64.encode(value, true);
}

export function isMicDeviceValid(deviceId, deviceLabel) {
  const preCondiditon = deviceId !== 'communications';

  return (
    preCondiditon &&
    !/ZoomAudioDevice/i.test(deviceLabel) &&
    !/Zoom-\S*/.test(deviceLabel) &&
    !/CubebAggregateDevice\S*/.test(deviceLabel)
  );
}

export function isSpeakerDeviceValid(deviceId, deviceLabel) {
  const preCondiditon = deviceId !== 'communications';

  return preCondiditon && !/ZoomAudioDevice/i.test(deviceLabel);
}

export const isOpera = () => /opera|opr\/[\d]+/i.test(navigator.userAgent);

export const isEdge = () => /edge\/(\d+)/i.test(navigator.userAgent);

export const isChrome = () => {
  const { userAgent } = navigator;
  return (
    !isOpera() &&
    !isEdge() &&
    /chrome/i.test(userAgent) &&
    /webkit/i.test(userAgent)
  );
};

export const isTeslaMode = () => {
  return /TESLA/.test(navigator.userAgent);
};

export const getVideoDPI = () => {
  if (isSpecialChromeOS() || isSpecialArmChromeOS()) {
    return {
      width: 320,
      height: 180,
    };
  }

  if (
    JsMediaSDK_Instance?.util?.get720pcapacity &&
    JsMediaSDK_Instance.util.get720pcapacity()
  ) {
    return {
      width: 1280,
      height: 720,
    };
  }

  return {
    width: 640,
    height: 360,
  };
};

export const isArm = () => {
  return /arm/i.test(navigator.userAgent);
};

export const isChromeOS = () => {
  return /\bCrOS\b/.test(navigator.userAgent);
};

export const isSpecialChromeOS = () => {
  return isChromeOS() && !isArm() && navigator.hardwareConcurrency < 4;
};
export const isSpecialArmChromeOS = () => {
  return isArmChromeOS() && navigator.hardwareConcurrency <= 4;
};

export const isArmChromeOS = () => isChromeOS() && isArm();

const isIPad = () => {
  const ua = navigator.userAgent.toLowerCase();
  let portraitWidth = 0;
  let portraitHeight = 0;

  // iPad height, resolution table width // same width Note that there is 1668px
  const screens = {
    1024: 768, // iPad mini 4, iPad Air 2, iPad Pro 9.7,
    1112: 834, // iPad Pro 10.5
    1194: 834, // iPad Pro 11
    1366: 1024, // iPad Pro 12.9 2nd
  };
  // held vertically held horizontally (portrait mode, landscape mode)
  // determines in portrait position either case an
  if (window.screen.width < window.screen.height) {
    portraitWidth = window.screen.width;
    portraitHeight = window.screen.height;
  } else {
    portraitWidth = window.screen.height;
    portraitHeight = window.screen.width;
  }

  // iOS and iPadOS return true if the iPad either case return
  return (
    ua.indexOf('ipad') > -1 ||
    (ua.indexOf('macintosh') > -1 && screens[portraitHeight] === portraitWidth)
  );
};

export const isMobileDevice = () =>
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  isIPad() ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i);

let vendorId;
export const isMSFTMode = () => {
  const controller = window.meetingHost;
  if (!controller) return false;
  if (!vendorId) {
    vendorId = controller.getHostState().vendorId;
  }
  if (vendorId !== CONTROL_MODE_CONNECTORS_ENUM.MSFT) {
    return false;
  }
  return true;
};

export const isMTRAndroid = () => {
  return /MSFT Teams Android Room/.test(navigator.userAgent);
};

export const isYealink = () => {
  return /yealink/.test(navigator.userAgent.toLowerCase());
};
export const isGoogleNestMode = () => {
  return /\bCrKey\b/.test(navigator.userAgent);
};

export const isGoogleMeetMode = () => {
  // todo meetingConfig?
  // return meetingConfig?.partnerId === 'GOOGLE-MEET';
  return false;
};

export const isExternalControlledMode = () => {
  return (
    isGoogleNestMode() ||
    isMSFTMode() ||
    isTeslaMode() ||
    isMTRAndroid() ||
    isGoogleMeetMode()
  );
};
