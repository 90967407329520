import React, {
  useEffect,
  useReducer,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import SettingsTransaction from '../transactions/SettingsTransaction';
import SettingsContext from '../context/SettingsContext';

function SettingsContainer(
  {
    onClose,
    defaultPreviewOptions,
    wcHash,
    baseUrl,
    ringUrl,
    type,
    onChange,
    children,
  },
  ref,
) {
  const [state, dispatch] = useReducer(
    SettingsTransaction.reducer,
    SettingsTransaction.initialState,
  );
  const transactionRef = useRef(null);

  if (transactionRef.current) {
    transactionRef.current.syncState(state);
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        getTransaction: () => transactionRef.current,
      };
    },
    [],
  );

  useEffect(() => {
    transactionRef.current = new SettingsTransaction(dispatch, {
      onClose,
      onChange,
      defaultPreviewOptions,
      wcHash,
      baseUrl,
      ringUrl,
      type,
      initialState: state,
    });

    return () => {
      transactionRef.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SettingsContext.Provider
      value={{ state, transaction: transactionRef.current }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsContainer.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  defaultPreviewOptions: PropTypes.number,
  wcHash: PropTypes.string,
  baseUrl: PropTypes.string,
  ringUrl: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
};

SettingsContainer.defaultProps = {
  onClose: () => {},
  defaultPreviewOptions: 0,
  wcHash: '',
  baseUrl: '',
  ringUrl: '',
};

export default forwardRef(SettingsContainer);
