/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import './Slider.scss';

const Slider = (props) => {
  const { value, onChange, ...rest } = props;
  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.valueAsNumber);
      }
    },
    [onChange],
  );
  return (
    <input
      type="range"
      name="volume"
      min="0"
      max="100"
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default Slider;
